import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";
import { Typography } from "styles/mixins";

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)};
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  ${up("lg")} {
    margin-top: ${({ theme }) => theme.spacing(16)};
    margin-bottom: ${({ theme }) => theme.spacing(16)};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${up("lg")} {
    margin-bottom: 0;
  }
`;

const Title = styled(UI.Text)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  ${up("lg")} {
    max-width: 15ch;
  }
`;

const Text = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  width: 80%;
`;

const StyledLink = styled(UI.Link)`
  ${Typography.t14Fakt}
`;

const MediaCaption = styled.div`
  ${Typography.t14Fakt}

  line-height: 1.2 !important;
  color: ${({ theme }) => theme.palette.someGrey};
  margin-top: ${({ theme }) => theme.spacing(1)};
  text-align: right;

  > pre {
    font-family: inherit;
  }
`;

const CardCalloutBlock: React.FC<Gatsby.CardCalloutBlockFragment> = ({ r }) => {
  const card = r?.field_card && r?.field_card[0];

  if (!card) {
    return null;
  }

  return (
    <Container>
      <UI.Wrap>
        <UI.Grid.Row colGap={{ sm: 0, lg: 2 }} vAlign="center">
          <UI.Grid.Col size={{ sm: 1, lg: 1 / 2 }} align="center">
            <Body>
              <Title as="h1" color={"anotherBlue"} typography={"h2Leitura"}>
                {card.field_title}
              </Title>
              {card.field_body?.value && (
                <Text>
                  <UI.Text
                    as="div"
                    typography={"t16Fakt"}
                    dangerouslySetInnerHTML={{ __html: card.field_body.value }}
                  />
                </Text>
              )}
              {card.field_link?.[0]?.uri && (
                <StyledLink to={card.field_link?.[0]?.uri} showUnderline={true}>
                  {card.field_link?.[0]?.title}
                </StyledLink>
              )}
            </Body>
          </UI.Grid.Col>
          <UI.Grid.Col size={{ sm: 1, lg: 1 / 2 }}>
            <UI.Media r={card.r} />
            {(card?.r?.media as any)?.imageCaption && (
              <MediaCaption>
                <pre>{(card?.r?.media as any)?.imageCaption}</pre>
              </MediaCaption>
            )}
          </UI.Grid.Col>
        </UI.Grid.Row>
      </UI.Wrap>
    </Container>
  );
};

export default CardCalloutBlock;
