import React from "react";
import styled from "styled-components";
import * as UI from "components/ui";
import { useFormatMessage } from "context/Intl";
import { up } from "styled-breakpoints";

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette["anotherLightBlue"]};
  margin-bottom: ${({ theme }) => theme.spacing(7)};
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};

  ${up("lg")} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(15)};
    padding-left: 10vw;
    padding-right: 10vw;
    flex-direction: row;
  }
`;

const Text = styled.div`
  max-width: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  ${up("lg")} {
    margin-bottom: 0;
  }
`;

const Image = styled.div`
  flex: 1;
  max-width: 115px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const CTA = styled.div`
  max-width: 240px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  text-align: center;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: 1;
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  ${up("lg")} {
    max-width: 340px;
    margin-left: 170px;
    order: 2;
    margin-bottom: 0;
  }
`;

const Downloads = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;

  a {
    width: 139px;
    height: 41px;
    display: block;
  }

  ${up("lg")} {
    width: 90%;
    a {
      width: 150px;
      height: 50px;
    }
  }
`;

const MobileCTABlock: React.FC<Gatsby.MobileCTABlockFragment> = ({ r, nid }) => {
  const formatMessage = useFormatMessage();
  const fieldCard = ((r?.f || [])[0]?.r?.f || [])[0];
  const body = fieldCard?.field_body?.value;
  const details = fieldCard?.r?.field_cta_details || [];

  const image = {
    ...(fieldCard?.r?.media?.r as any)?.image?.localFile,
    altName: (fieldCard?.r?.media as any)?.imageDetails?.alt || "",
  };

  return (
    <UI.Wrap>
      <Container>
        {body && (
          <Text>
            <UI.Text typography={"h4Leitura"} dangerouslySetInnerHTML={{ __html: body }} />
          </Text>
        )}
        <Info>
          <Image>
            <UI.Image style={{ height: "100%" }} {...image} alt={image?.altName} />
          </Image>
          <CTA>
            <UI.Text typography={"t18Fakt"}>{formatMessage("Download the MyChart app")}</UI.Text>
          </CTA>
          {/*body && <UI.RichText content={body} />*/}
          <Downloads>
            {details.map((d: any, i: number) => {
              const uri = d?.field_mobile_download?.uri;

              const image = {
                ...(d?.r?.media?.r as any)?.image?.localFile,
                altName: (d?.r?.media as any)?.imageDetails?.alt || "",
              };

              return (
                <a target="_blank" rel="noreferrer" href={uri} key={nid + "" + i}>
                  <UI.Image {...image} alt={image?.altName} />
                </a>
              );
            })}
          </Downloads>
        </Info>
      </Container>
    </UI.Wrap>
  );
};

export default MobileCTABlock;
