import React from "react";
import styled, { css } from "styled-components";
import * as UI from "components/ui";
import { up } from "styled-breakpoints";
import { Typography } from "styles/mixins";

const OuterWrap = styled(UI.Wrap)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.lineGrey};
`;

const Container = styled.div<{ isMediaVideo?: boolean; backgroundColor?: string }>`
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  background-color: ${({ backgroundColor, theme }) => (backgroundColor ? theme.palette["anotherLightBlue"] : "none")};
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  ${({ isMediaVideo, theme }) => isMediaVideo && `padding-bottom: ${theme.spacing(3)};`}

  ${up("lg")} {
    margin-top: ${({ theme }) => theme.spacing(13)};
    margin-bottom: ${({ theme }) => theme.spacing(13)};
    padding-top: ${({ theme }) => theme.spacing(10)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(10)};
    padding-right: ${({ theme }) => theme.spacing(10)};

    ${({ isMediaVideo }) =>
      isMediaVideo &&
      css`
        padding-top: 80px;
        padding-bottom: 96px;
        padding-right: 88px;
        padding-left: 120px;
      `}
  }

  ${up("xl")} {
    ${({ isMediaVideo }) => !isMediaVideo && "padding-right: 0;"}
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${up("lg")} {
    flex-direction: row;
  }
`;

const Left = styled.div`
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing(8)};

  ${up("lg")} {
    padding-bottom: 0;
  }
`;

const Right = styled.div<{ isMediaVideo?: boolean }>`
  flex: ${({ isMediaVideo }) => (isMediaVideo ? "1" : "1.2")};

  > img {
    max-width: 100%;
  }

  ${up("xl")} {
    margin-top: ${({ isMediaVideo, theme }) => !isMediaVideo && `-${theme.spacing(12)}`};
  }

  ${up("max")} {
    margin-top: ${({ isMediaVideo, theme }) => !isMediaVideo && `-${theme.spacing(18)}`};
  }
`;

const InfoList = styled.ul<{ isMediaVideo?: boolean }>`
  ${up("lg")} {
    ${({ isMediaVideo }) =>
      isMediaVideo &&
      css`
        max-width: 444px;
        padding-right: 16px;
      `}
  }
`;

const InfoItem = styled.li<{ useSquareBullets?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ useSquareBullets }) =>
    useSquareBullets &&
    css`
      padding-left: 22px;
      margin-bottom: 12px;

      &::before {
        position: absolute;
        width: 6px;
        height: 6px;
        left: 0px;
        top: 7px;
        background: ${({ theme }) => theme.palette.anotherBlue};
        content: " ";
      }
    `}
`;

const Icon = styled.div`
  width: ${({ theme }) => theme.spacing(5)};
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

const Actions = styled.div<{ isMediaVideo?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${({ isMediaVideo, theme }) => (isMediaVideo ? "48px" : theme.spacing(8))};
  justify-content: center;

  ${up("lg")} {
    justify-content: flex-start;
  }
`;

const SignupButton = styled(UI.Button)`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

const LoginLink = styled(UI.Link)`
  ${Typography.t14Fakt}
`;

const Headline = styled(UI.Text)<{ isMediaVideo?: boolean }>`
  display: block;
  margin-bottom: ${({ isMediaVideo, theme }) => (isMediaVideo ? "24px" : theme.spacing(5))};
  position: relative;
  z-index: 1;

  ${up("lg")} {
    ${({ isMediaVideo }) =>
      isMediaVideo &&
      css`
        font-size: 51px;
        line-height: 65px;
      `}
  }

  ${up("xl")} {
    max-width: 24ch;
  }
`;

const FieldTitle = styled(UI.Text)<{ useSquareBullets?: boolean }>`
  font-weight: bold;

  ${({ useSquareBullets }) =>
    useSquareBullets &&
    css`
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.1px;
    `}

  ${up("lg")} {
    ${({ useSquareBullets }) =>
      useSquareBullets &&
      css`
        font-size: 16px;
        line-height: 140%;
      `}
  }
`;

const ChartMedia = styled.div<{ isMediaVideo?: boolean }>`
  ${up("lg")} {
    ${({ isMediaVideo }) =>
      isMediaVideo &&
      css`
        margin-top: 24px;
      `}
  }
`;

const MyChartBlock: React.FC<Gatsby.MyChartBlockFragment> = ({ r, field_background_color, nid, field_title }) => {
  const items = r?.field_mychart_content?.find((c: any) => c?.__typename === "paragraph__mychart_items");
  const media = r?.field_mychart_content?.find(
    (c: any) => c?.__typename === "paragraph__media"
  ) as Gatsby.MediaBlockFragment;
  const content = (items?.r as any)?.field_mychart_items_content;
  const links = (items?.r as any)?.field_mychart_items_links;
  const signup = links.length > 0 ? links[0].field_link[0] : null;
  const login = links.length > 1 ? links[1].field_link[0] : null;
  const isMediaVideo = media?.r?.media?.__typename === "media__remote_video";
  const contentListHasIcons = content.some((c: any) => {
    const icon = c?.r?.media?.r?.image?.localFile;
    return typeof icon !== "undefined";
  });

  return (
    <OuterWrap>
      <Container isMediaVideo={isMediaVideo} backgroundColor={field_background_color}>
        <Headline isMediaVideo={isMediaVideo} as="h1" typography={"h2Leitura"} color={"anotherBlue"}>
          {field_title}
        </Headline>
        <InfoWrapper>
          <Left>
            <InfoList isMediaVideo={isMediaVideo}>
              {content.map((c: any, i: number) => {
                const icon = c?.r?.media?.r?.image?.localFile;

                return (
                  <InfoItem key={nid + "" + i} useSquareBullets={!contentListHasIcons}>
                    {contentListHasIcons && icon && (
                      <Icon>
                        <UI.Image {...icon} />
                      </Icon>
                    )}
                    <FieldTitle useSquareBullets={!contentListHasIcons} typography={"t20Fakt"} color="anotherBlue">
                      {c.field_title}
                    </FieldTitle>
                  </InfoItem>
                );
              })}
            </InfoList>

            <Actions isMediaVideo={isMediaVideo}>
              {signup?.uri && (
                <SignupButton variant="secondary">
                  <UI.Link to={signup.uri}>
                    <UI.Text typography={"t16Fakt"} color={"white"}>
                      {signup.title}
                    </UI.Text>
                  </UI.Link>
                </SignupButton>
              )}
              {login?.uri && (
                <LoginLink to={login.uri} showUnderline={true}>
                  {login.title}
                </LoginLink>
              )}
            </Actions>
          </Left>
          <Right isMediaVideo={isMediaVideo}>
            <ChartMedia isMediaVideo={isMediaVideo}>{media && <UI.Media {...media} />}</ChartMedia>
          </Right>
        </InfoWrapper>
      </Container>
    </OuterWrap>
  );
};

export default MyChartBlock;
