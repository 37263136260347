import React, { useEffect, useState, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { down, up } from "styled-breakpoints";
import * as UI from "components/ui";
import { useIntl } from "context/Intl";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ theme }) => theme.breakpoints.max}px;
  width: 90vw;
  overflow: hidden;
`;

const LargeCard = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  ${down("md")} {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

const SmallCard = styled(UI.Grid.Col)`
  > a {
    display: block;
  }
`;

const HeroText = styled.div<{ docked: boolean; left: number }>`
  background-color: ${({ theme }) => theme.palette["white"]};
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(2)};
  left: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  z-index: 1;
  width: ${({ theme, docked, left }) => `calc(100% - ${docked ? left + "px" : theme.spacing(4)})`};

  ${up("lg")} {
    position: ${({ docked }) => (docked ? "fixed" : "absolute")};
    bottom: ${({ theme }) => theme.spacing(4)};
    left: ${({ theme, docked, left }) => (docked ? left + "px" : theme.spacing(4))};
    padding: ${({ theme }) => theme.spacing(5)};
    width: 70%;
    max-width: 750px;
  }
`;

const HeroImage = styled.div`
  height: 70vh;

  ${up("lg")} {
    height: 100%;
  }
`;

const SmallImage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const SmallHeadline = styled(UI.Text)`
  font-weight: bold;
  transition: opacity 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);
  will-change: aopacity;

  &:hover {
    opacity: 0.8;
  }
`;

const Sticker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 14rem;
  height: 14rem;

  > img {
    height: 100%;
    width: 100%;
  }

  ${up("lg")} {
    left: auto;
    right: 0;
    width: 21rem;
    height: 21rem;
  }
`;

const HeroComponentBlock: React.FC<Gatsby.HeroComponentBlockFragment> = ({ r, nid }) => {
  const { locale: activeLocale } = useIntl();

  const heroCard = (r?.field_hero_component_content?.r?.field_card || [])[0] || null;
  const smallCards = r?.field_hero_component_cards || [];

  const textRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);
  const [dockText, setDockText] = useState(true);
  const [textLeft, setTextLeft] = useState(0);
  const { fonts } = useTheme();

  const heroImage = {
    ...(heroCard?.r?.media?.r as any)?.image?.localFile,
    altName: (heroCard?.r?.media as any)?.imageDetails?.alt || heroCard?.field_title || "",
  };

  const watchScroll = () => {
    if (imgRef.current) {
      const imgRect = imgRef.current.getBoundingClientRect();
      if (imgRect.bottom > window.innerHeight) {
        setDockText(true);
      } else {
        setDockText(false);
      }
    }
  };

  const setTextPosition = () => {
    if (imgRef.current) {
      setTextLeft(imgRef.current.getBoundingClientRect().left + 32);
    }
  };

  const FormatedHeroTitle = () => {
    const [sufix, ...prefix] = heroCard?.field_title?.split(" ") || [];
    if (!sufix || !prefix || sufix !== "COVID-SAFE") return <>{heroCard?.field_title}</>;

    return (
      <>
        <span style={{ fontFamily: fonts.fakt }}>{sufix}</span> <span>{prefix.join(" ")}</span>
      </>
    );
  };

  useEffect(() => {
    window.document.addEventListener("scroll", watchScroll);
    window.document.addEventListener("touchmove", watchScroll);
    window.addEventListener("resize", setTextPosition);

    setTextPosition();
    watchScroll();

    return () => {
      window.document.removeEventListener("scroll", watchScroll);
      window.document.removeEventListener("touchmove", watchScroll);
      window.removeEventListener("resize", setTextPosition);
    };
  }, []);

  return (
    <Container>
      <UI.Grid.Row colGap={{ lg: 2, xl: 2 }}>
        <UI.Grid.Col size={{ sm: 1, lg: 3 / 4 }} align="stretch">
          {heroCard && (
            <LargeCard>
              <HeroImage ref={imgRef}>
                {heroImage && <UI.Image style={{ height: "100%" }} {...heroImage} alt={heroImage?.altName} />}
              </HeroImage>
              <HeroText as="header" ref={textRef} docked={dockText} left={textLeft}>
                <UI.Text as="h1" color={"anotherBlue"} typography={"h1LeituraSmall"}>
                  <FormatedHeroTitle />
                </UI.Text>
                <UI.Text as="h2" color={"greyNightmare"} typography={"t24Leitura"}>
                  {heroCard.field_subhead}
                </UI.Text>
              </HeroText>
              <Sticker>
                {activeLocale === "es" ? (
                  <img src="/assets/covid-sticker-es.png" alt="Salud a Salvo de Covid" />
                ) : (
                  <img src="/assets/covid-sticker.png" alt="Covid Alert" />
                )}
              </Sticker>
            </LargeCard>
          )}
        </UI.Grid.Col>
        <UI.Grid.Col size={{ sm: 1, lg: 1 / 4 }} align={"stretch"}>
          <UI.Grid.Row rowGap={5}>
            {smallCards.map((card: any, i: number) => {
              const smallImage = {
                ...card.r?.media?.r?.image?.localFile,
                altName: card.r?.media?.imageDetails?.alt || card.field_title || "",
              };

              return (
                <SmallCard as="article" size={1} key={nid + "" + i}>
                  <UI.Link to={card.field_link?.[0]?.uri} title={card.field_title} aria-label={card.field_title}>
                    {smallImage && (
                      <SmallImage>
                        <UI.Image {...smallImage} alt={smallImage?.altName} />
                      </SmallImage>
                    )}
                    <SmallHeadline as="h1" typography={"t20Fakt"} gutter={1}>
                      {card.field_title}
                    </SmallHeadline>
                    <UI.Text
                      as="div"
                      typography={"t16Leitura"}
                      dangerouslySetInnerHTML={{ __html: card.field_body?.value || "" }}
                    />
                  </UI.Link>
                </SmallCard>
              );
            })}
          </UI.Grid.Row>
        </UI.Grid.Col>
      </UI.Grid.Row>
    </Container>
  );
};

export default HeroComponentBlock;
