import React, { useEffect, useRef, useState } from "react";
import { uid } from "react-uid";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { useIntl } from "context/Intl";
import * as UI from "components/ui";
import useMediaQuery from "hooks/useMediaQuery";
import { Typography } from "styles/mixins";

const Title = styled.div`
  // font-size: 40px;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-top: ${({ theme }) => theme.spacing(3)};

  ${up("lg")} {
    padding-bottom: ${({ theme }) => theme.spacing(7)};
    padding-top: ${({ theme }) => theme.spacing(7)};
  }
`;

const Alphabet = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-top: ${({ theme }) => theme.spacing(3)};
  border-bottom: 1px solid ${({ theme }) => theme.palette["lightGrey"]};
  border-top: 1px solid ${({ theme }) => theme.palette["lightGrey"]};
  top: 0;
  background-color: white;
  width: 100%;
  overflow-x: auto;
  justify-content: flex-start;
  left: 0;
  z-index: 0;
  box-sizing: border-box;

  ${up("lg")} {
    padding-right: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(7)};
    padding-top: ${({ theme }) => theme.spacing(7)};
    right: 0;
    width: 100%;
    overflow: auto;
  }
`;

const DockedAlphabet = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-top: ${({ theme }) => theme.spacing(3)};
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  overflow-x: auto;
  justify-content: flex-start;
  padding-left: ${({ theme }) => theme.spacing(3)};
  position: fixed;
  z-index: 900;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${up("lg")} {
    padding-right: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(7)};
    padding-top: ${({ theme }) => theme.spacing(7)};
    right: 0;
    width: 100%;
    position: fixed;
    overflow: auto;
  }
`;

const AlphaScroll = styled.ul`
  display: flex;

  ${up("lg")} {
    justify-content: space-between;
    width: 100%;
  }
`;

const ActiveLink = styled.li<{ active: boolean }>`
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ active, theme }) => (active ? theme.palette["anotherBlue"] : "white")};
  padding-top: 4px;

  > a {
    display: inline-block;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette["anotherBlue"]};
  }
`;

const InactiveLink = styled.li`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  padding-top: 4px;
`;

const ExpandableChildren = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  flex-direction: column;

  ${up("lg")} {
    flex-direction: row;
    padding-bottom: ${({ theme }) => theme.spacing(8.5)};
  }
`;

const Info = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  flex: 1;

  ${up("lg")} {
    margin-bottom: 0;
    padding-right: ${({ theme }) => theme.spacing(6)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${Typography.t18Fakt}
    font-weight: bold;
  }

  ul > li:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const Map = styled.div`
  width: 100vw;
  height: 100vw;
  flex: 1;
  ${up("lg")} {
    width: 400px;
    height: 560px;
  }
`;

const Anchor = styled.span`
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;

  ${up("lg")} {
    top: -150px;
  }
`;

type Taxonomy =
  | (Pick<Gatsby.taxonomy_term__departments, "name"> & {
      readonly description: Gatsby.Maybe<Pick<Gatsby.taxonomy_term__departmentsDescription, "value">>;
      readonly r: Gatsby.Maybe<{
        readonly field_locations: Gatsby.Maybe<ReadonlyArray<Gatsby.Maybe<Gatsby.FieldLocationsFieldsFragment>>>;
      }>;
    })
  | (Pick<Gatsby.taxonomy_term__departments_spanish, "name"> & {
      readonly description: Gatsby.Maybe<Pick<Gatsby.taxonomy_term__departments_spanishDescription, "value">>;
      readonly r: Gatsby.Maybe<{
        readonly field_locations: Gatsby.Maybe<ReadonlyArray<Gatsby.Maybe<Gatsby.FieldLocationsFieldsFragment>>>;
      }>;
    });
type Taxonomies = Taxonomy[];
type AllTaxonomies = Record<string, Taxonomies>;

const TaxonomiesSection: React.FC<{
  currentLetter: string;
  setCurrentLetter: (state: string) => void;
  letter: string;
  nid?: number;
  taxonomies: Taxonomies;
}> = ({ currentLetter, setCurrentLetter, letter, taxonomies, nid }) => {
  const isLg = useMediaQuery("lg");
  const letterSectionRef = useRef<HTMLDivElement>(null);

  // Handle active letter
  useEffect(() => {
    const watchScroll = (event: Event) => {
      if (letterSectionRef.current) {
        // The considered height of alphabet bar
        const offset = isLg ? 150 : 90;

        const element = letterSectionRef.current;
        const { top, bottom } = element.getBoundingClientRect();

        // Bottom of alphabet bar is within a letter section
        const isActive = top - offset <= 0 && bottom - offset > 0;

        if (isActive && currentLetter !== letter) {
          setCurrentLetter(letter);
        }
      }
    };

    window.document.addEventListener("scroll", watchScroll);
    window.document.addEventListener("touchmove", watchScroll);

    return () => {
      window.document.removeEventListener("scroll", watchScroll);
      window.document.removeEventListener("touchmove", watchScroll);
    };
  }, [currentLetter, isLg, letter, setCurrentLetter]);

  return (
    <div ref={letterSectionRef}>
      <Anchor id={letter} />
      {taxonomies.map((ref, i) => {
        const locations = ref?.r?.field_locations;
        const description = ref?.description?.value?.replace(/\r?\n|\r/g, "");
        const markers =
          locations?.map((l) => ({
            position: l?.field_geocode,
            title: l?.field_title,
            location: l?.field_location,
          })) || [];

        return (
          <UI.Expandable
            managed={nid + "" + i}
            title={ref?.name as string}
            key={uid(nid + "" + i)}
            scrollOffset={isLg ? 150 : 90}
          >
            <ExpandableChildren>
              <Info>{description && <UI.RichText content={description} />}</Info>
              <Map>
                {markers.length > 0 && (
                  <UI.Map
                    markers={markers}
                    center={
                      markers[0]!.position as {
                        lat?: number | undefined;
                        lng?: number | undefined;
                      }
                    }
                    zoom={10}
                  />
                )}
              </Map>
            </ExpandableChildren>
          </UI.Expandable>
        );
      })}
    </div>
  );
};

const GlossaryBlock: React.FC<Gatsby.GlossaryBlockFragment> = ({ title, r, nid }) => {
  const { locale: activeLocale } = useIntl();
  const references =
    r?.field_vocabulary_reference?.filter((taxonomy) => {
      if (activeLocale === "es") {
        // Unfortunately, enabling types for __typename can't be configured in gatsby-plugin-typegen.
        // TODO: Consider switching to gatsby-plugin-graphql-codegen.
        // @ts-ignore
        return taxonomy.__typename === "taxonomy_term__departments_spanish";
      }

      // @ts-ignore
      return taxonomy.__typename === "taxonomy_term__departments";
    }) || [];
  const alphabet = [...Array(26)].map((_, i) => ({ value: String.fromCharCode(i + 65), hasEntry: false }));
  const alphaRef = useRef<HTMLDivElement>(null);
  const [dockAlphabet, setDockAlphabet] = useState(false);
  const [currentLetter, setCurrentLetter] = useState("A");
  const dockPos = useRef(-1);

  const getFirstChar = (name?: string) => {
    // Ignore the leading "The"
    if (name && name.toLowerCase().startsWith("the ")) {
      return name.toLowerCase().replace("the ", "").charAt(0).toUpperCase();
    }

    if (name) {
      return name.charAt(0).toUpperCase();
    }

    return "A";
  };

  const allTaxonomies = references.reduce<AllTaxonomies>((acc, value) => {
    if (!value) return acc;

    const firstChar = getFirstChar(value.name);

    if (!acc[firstChar]) {
      acc[firstChar] = [value];

      return acc;
    }

    acc[firstChar].push(value);

    return acc;
  }, {});

  const createAlphabet = () => {
    references?.forEach((ref) => {
      if (ref?.name) {
        const sL = getFirstChar(ref?.name);
        const lI = alphabet.findIndex((a) => a.value === sL);
        const hasEntry = lI !== -1 && alphabet[lI]?.hasEntry;

        if (!hasEntry && alphabet[lI]) {
          alphabet[lI].hasEntry = true;
        }
      }
    });

    // create Links
    return alphabet.map((letter, i) => {
      if (letter.hasEntry) {
        return (
          <ActiveLink
            key={uid("l" + nid + i)}
            onClick={() => setCurrentLetter(letter.value)}
            active={currentLetter === letter.value}
          >
            <a href={"#" + letter.value}>
              <UI.Text typography="t24Leitura" color="anotherBlue">
                {letter.value}
              </UI.Text>
            </a>
          </ActiveLink>
        );
      } else {
        return (
          <InactiveLink key={uid("l" + nid + i)}>
            <UI.Text typography="t24Leitura" color="lineGrey">
              {letter.value}
            </UI.Text>
          </InactiveLink>
        );
      }
    });
  };

  // Handle sticky alphabet dock
  useEffect(() => {
    if (!alphaRef) return;

    dockPos.current = Number(alphaRef.current?.getBoundingClientRect()?.top);

    const watchScroll = (event: Event) => {
      const el = document.scrollingElement || document.documentElement;
      const current = el.scrollTop;

      setDockAlphabet(dockPos.current < current);
    };

    window.document.addEventListener("scroll", watchScroll);
    window.document.addEventListener("touchmove", watchScroll);

    return () => {
      window.document.removeEventListener("scroll", watchScroll);
      window.document.removeEventListener("touchmove", watchScroll);
    };
  }, []);

  return (
    <UI.Wrap>
      {title && (
        <Title>
          <UI.Text typography="h3Leitura">{title}</UI.Text>
        </Title>
      )}
      {dockAlphabet && (
        <DockedAlphabet>
          <AlphaScroll>{createAlphabet()}</AlphaScroll>
        </DockedAlphabet>
      )}
      <Alphabet ref={alphaRef}>
        <AlphaScroll>{createAlphabet()}</AlphaScroll>
      </Alphabet>
      <div>
        {Object.keys(allTaxonomies).map((letter) => {
          return (
            <TaxonomiesSection
              letter={letter}
              key={letter}
              nid={nid}
              taxonomies={allTaxonomies[letter]}
              currentLetter={currentLetter}
              setCurrentLetter={setCurrentLetter}
            />
          );
        })}
      </div>
    </UI.Wrap>
  );
};

export default GlossaryBlock;
