import React from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import * as UI from "components/ui";

const BlocksContainer = styled.div`
  display: block;
  padding-top: ${({ theme }) => theme.spacing(6)};

  ${down("md")} {
    ${UI.Grid.Col}:first-child > article {
      padding-bottom: 0 !important;
    }

    ${UI.Grid.Col}:last-child > article {
      padding-top: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

const BlocksHeadline = styled(UI.Text)`
  display: block;
  text-align: center;
`;

const PolicyBlock: React.FC<Gatsby.PolicyBlockFragment> = ({ field_body, field_body2, field_title }) => {
  const getTitleFromBody = (body: string) => {
    // return h1 tag from the body string
    return (body.match(/<h1( |>).*?<\/h1>/gi) || [""])[0].replace(/<\/?[^>]+(>|$)/g, "");
  };

  const getRawBody = (body: string) => {
    // return h1 tag removed body string
    return body.replace(/<h1( |>).*?<\/h1>/gi,"");
  };

  return (
    <UI.Wrap>
      <BlocksContainer>
        <BlocksHeadline as="h1" typography="h3Leitura" gutter={4}>
          {field_title}
        </BlocksHeadline>
        <UI.Grid.Row colGap={{ sm: 0, lg: 2 }} vAlign="stretch">
          <UI.Grid.Col size={{ sm: 1, lg: 1 / 2 }}>
            <UI.Card
              fillParent={true}
              title={getTitleFromBody(field_body.value)}
              backgroundColor="blue"
              layout="no-image"
              body={getRawBody(field_body.value)}
            />
          </UI.Grid.Col>
          <UI.Grid.Col size={{ sm: 1, lg: 1 / 2 }}>
            <UI.Card
              fillParent={true}
              title={getTitleFromBody(field_body2.value)}
              backgroundColor="blue"
              layout="no-image"
              body={getRawBody(field_body2.value)}
            />
          </UI.Grid.Col>
        </UI.Grid.Row>
      </BlocksContainer>
    </UI.Wrap>
  );
};

export default PolicyBlock;
