import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";

/**
 * To make this component work on IE11 we had to install and use Slick.
 * Preferably we would use Swiper / React Id Swiper since is the carrousel
 * we use on most of the projects, but since version 5 and above, Swiper drop
 * support to IE11 and React Id Swiper does not support the Fade effect with autoplay.
 * We were also not able to use `onCreateWebpackConfig` to transpile `dom7`.
 */

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  ${up("lg")} {
    margin-top: ${({ theme }) => theme.spacing(8)};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }

  ${up("xl")} {
    margin-top: 77px;
    margin-bottom: 104px;
  }
`;

const Wrap = styled(UI.Wrap)`
  padding-bottom: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lineGrey};

  ${up("lg")} {
    padding-bottom: 66px;
  }

  ${up("xl")} {
    padding-bottom: 79px;
  }
`;

const Title = styled(UI.Text)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.5px;

  ${up("lg")} {
    font-size: 32px;
    line-height: 40px;
  }

  ${up("xl")} {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
    font-size: 51px;
    line-height: 54px;
  }
`;

const Body = styled(UI.Text)`
  & > p {
    letter-spacing: 0.533333px;
  }
`;

const LinkWrapper = styled.div`
  // This div was added because of the Col flex,
  // otherwise the link occupies the full width of the Col
  margin-top: 18px;

  ${up("xl")} {
    margin-top: 26px;
  }
`;

const Link = styled(UI.Link)`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #020322;
  border-bottom: 2px solid #153764;
`;

const MediaWrapper = styled.div`
  margin-top: 42px;

  ${up("lg")} {
    width: 100%;
    max-width: 283px;
    margin-top: 0;
    margin-left: auto;
  }

  ${up("xl")} {
    max-width: 580px;
    margin-right: 78px;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  background: ${({ theme }) => theme.palette.anotherLightBlue};
`;

const Caption = styled(UI.Text)`
  margin-top: ${({ theme }) => theme.spacing(2)};

  & > p {
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.533333px;
    color: #93afb9;
  }
`;

const CalloutMediaSliderBlock: React.FC<Gatsby.CalloutMediaSliderBlockFragment> = ({ body, header, link, r }) => {
  const settings = {
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  };

  return (
    <Container>
      <Wrap>
        <UI.Grid.Row colGap={{ sm: 0, lg: 2 }} vAlign="center">
          <UI.Grid.Col size={{ sm: 1, lg: 2 / 3, xl: 1 / 2 }}>
            <Title as="h1" typography={"h2Leitura"} color={"anotherBlue"}>
              {header}
            </Title>
            {body?.value && <Body as="div" typography={"t16Fakt"} dangerouslySetInnerHTML={{ __html: body.value }} />}
            {link && link[0]?.uri && (
              <LinkWrapper>
                <Link to={link[0].uri} showUnderline={true}>
                  {link[0].title}
                </Link>
              </LinkWrapper>
            )}
          </UI.Grid.Col>
          <UI.Grid.Col size={{ sm: 1, lg: 1 / 3, xl: 1 / 2 }}>
            <MediaWrapper>
              {r?.mediaItems && (
                <Slider {...settings}>
                  {r.mediaItems.map((image, index) => {
                    return (
                      <div key={index}>
                        <ImageWrapper>
                          <UI.Image {...image?.r?.image?.localFile} alt={image?.imageDetails?.alt} />
                        </ImageWrapper>
                        {image?.imageCaption && (
                          <Caption
                            as="div"
                            typography={"t16Fakt"}
                            dangerouslySetInnerHTML={{ __html: '<p>' + image.imageCaption + '</p>'}}
                          />
                        )}
                      </div>
                    );
                  })}
                </Slider>
              )}
            </MediaWrapper>
          </UI.Grid.Col>
        </UI.Grid.Row>
      </Wrap>
    </Container>
  );
};

export default CalloutMediaSliderBlock;
