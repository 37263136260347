import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import { up } from "styled-breakpoints";
import useMediaQuery from "hooks/useMediaQuery";
import useWindowSize from "hooks/useWindowSize";
import * as UI from "components/ui";
import ChevronRightIcon from "assets/chevron-right.svg";
import ChevronLeftIcon from "assets/chevron-left.svg";

type ISlide = {
  title?: string;
  body?: string;
  image?: any;
};

interface IProps {
  slides?: ISlide[];
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const Gallery = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  /* Fixes weird slides outline */
  * {
    outline: none !important;
  }
`;

const StyledSlide = styled(Slide)`
  position: relative !important;

  .carousel__inner-slide {
    display: flex;
    flex-direction: column;
  }

  .gatsby-image-wrapper {
    height: 100vw;
  }

  ${up("lg")} {
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const SlideInfo = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};

  > div {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 2;
  }

  ${up("lg")} {
    color: ${({ theme }) => theme.palette.white};
    padding-top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-end;

    &:after {
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 80%);
      mix-blend-mode: normal;
      opacity: 0.6;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    > div {
      padding-top: ${({ theme }) => theme.spacing(7)};
      padding-bottom: ${({ theme }) => theme.spacing(7)};
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  ${up("xl")} {
    > div {
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

const SlideInfoHeadline = styled(UI.Text)`
  display: block;

  ${up("lg")} {
    padding-right: 5rem;
    white-space: nowrap;
  }

  ${up("xl")} {
    padding-right: 0;
  }
`;

const SlideInfoBody = styled(UI.Text)`
  display: block;

  ${up("lg")} {
    width: 50%;
  }

  ${up("xl")} {
    margin: auto;
    max-width: 732px;
    padding-right: 10rem;
  }
`;

const Controls = styled.div<{ yOffset?: number }>`
  white-space: nowrap;
  position: absolute;
  bottom: ${({ yOffset = 0 }) => `calc(${yOffset}px + 14rem)`};
  left: 5vw;
  z-index: 3;

  > button:first-child {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  ${up("md")} {
    bottom: ${({ yOffset = 0 }) => `calc(${yOffset}px + 6.4rem)`};
  }

  ${up("lg")} {
    top: auto;
    bottom: ${({ theme }) => theme.spacing(3)};
  }

  ${up("xl")} {
    left: auto;
    top: auto;
    right: 5vw;
    bottom: ${({ yOffset = 0 }) => `calc((${yOffset}px - 4.8rem) / 2)`};
  }

  /* FIXME: rm this dirty hack! */
  @media (min-width: 1778px) {
    right: ${({ theme }) => `calc((100vw - ${theme.breakpoints.max}px) / 2)`};
  }
`;

const ControlButton = styled(UI.Button)`
  backface-visibility: hidden;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  will-change: transform opacity;

  &[disabled] {
    pointer-events: none;
    opacity: 0.25;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const GalleryCarousel: React.FC<IProps> = ({ slides }) => {
  const isLg = useMediaQuery("lg");
  const isXl = useMediaQuery("xl");
  const viewportSize = useWindowSize();
  const $swiper = useRef<any | null>(null);
  const [controlYOffset, setControlYOffset] = useState(0);
  const isIE11 = typeof window !== "undefined" && !!window.MSInputMethodContext && !!(document as any).documentMode;

  useEffect(() => {
    if (!$swiper.current?.instance?.sliderElement) return;
    const infoHeight = $swiper.current?.instance?.sliderElement.querySelector(UI.Wrap)?.clientHeight;
    setControlYOffset(infoHeight);
  }, [viewportSize, isXl]);

  if (typeof window === "undefined" || !slides || slides.length === 0) return null;

  return (
    <Container>
      <CarouselProvider
        dragEnabled={false}
        naturalSlideWidth={100}
        naturalSlideHeight={isLg ? 56.25 : 100}
        isIntrinsicHeight={isLg === false && !isIE11}
        lockOnWindowScroll={true}
        totalSlides={slides.length}
        visibleSlides={1}
      >
        <Gallery>
          <Slider aria-label="Visiting guide" trayTag="div" ref={$swiper} moveThreshold={0.25}>
            {slides.map((sl, i) => (
              <StyledSlide tag="article" index={i} key={sl.title}>
                <UI.Image {...sl.image} alt={sl.image?.altName} />
                <SlideInfo>
                  <UI.Wrap>
                    <SlideInfoHeadline as="h1" typography={isLg ? "h4Leitura" : "h2Leitura"} gutter={!isLg ? 2 : 0}>
                      {sl.title}
                    </SlideInfoHeadline>
                    {sl.body && <SlideInfoBody as="div" dangerouslySetInnerHTML={{ __html: sl.body }} />}
                  </UI.Wrap>
                </SlideInfo>
              </StyledSlide>
            ))}
          </Slider>
          <Controls yOffset={controlYOffset}>
            <ControlButton as={ButtonBack} variant="grey" round={true}>
              <UI.Icon>
                <ChevronLeftIcon />
              </UI.Icon>
            </ControlButton>
            <ControlButton as={ButtonNext} variant="grey" round={true}>
              <UI.Icon>
                <ChevronRightIcon />
              </UI.Icon>
            </ControlButton>
          </Controls>
        </Gallery>
      </CarouselProvider>
    </Container>
  );
};

export default GalleryCarousel;
