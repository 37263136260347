import React, { useMemo } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { up, down } from "styled-breakpoints";
import * as UI from "components/ui";
import { Typography } from "styles/mixins";

const Container = styled.header`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${up("lg")} {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

const MediaCaption = styled.div`
  ${Typography.t14Fakt}

  line-height: 1.2 !important;
  color: ${({ theme }) => theme.palette.someGrey};
  margin-top: ${({ theme }) => theme.spacing(1)};
  text-align: right;

  > pre {
    font-family: inherit;
  }
`;

const Header = styled.div`
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.palette.anotherBlue};
  padding-top: ${({ theme }) => theme.spacing(7)};
  padding-bottom: ${({ theme }) => theme.spacing(7)};

  /* Override the max-width hack for small devices */
  ${down("lg")} {
    & h1 {
      max-width: 100% !important;
    }
  }

  ${up("lg")} {
    padding-top: ${({ theme }) => theme.spacing(14)};
    padding-bottom: ${({ theme }) => theme.spacing(14)};

    & h1 {
      max-width: 14ch;
      margin-left: auto;
      margin-right: auto;
    }

    & h2 {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Media = styled.div`
  text-align: center;
`;

const HeroBlock: React.FC<Gatsby.HeroBlockFragment> = ({ r }) => {
  const card = r?.card && r.card[0] ? r.card[0] : undefined;
  const { title, subTitle, r: cardRelation } = card || {};
  const media = cardRelation?.media!;

  const mediaCaptionIsVisible = useMemo(() => {
    if (typeof window === "undefined") return false;
    return window.location.pathname.includes("/faq") || window.location.pathname.includes("/preguntas-frecuentes");
  }, []);

  // FIXME: this is hacky, but as long as this isn't fixed in the cms, that's the way to go
  const titleWidth = { maxWidth: "14ch" };
  const marginStyle = { marginBottom: "8rem" };

  if (typeof window !== "undefined") {
    const path = window.location.pathname;

    if (
      path.includes("/faq") ||
      path.includes("/services-locations") ||
      path.includes("/preguntas-frecuentes") ||
      path.includes("/actualizaciones-sobre-servicios-y-centros")
    ) {
      marginStyle.marginBottom = "0";

      if (path.includes("/faq")) {
        titleWidth.maxWidth = "24ch";
      }
    }
  }

  return (
    <Container style={marginStyle}>
      <Header>
        <UI.Wrap>
          {title && (
            <UI.Text as="h1" typography="h1Leitura" gutter={{ sm: 2, lg: 4 }} style={titleWidth}>
              {title}
            </UI.Text>
          )}
          {subTitle && (
            <UI.Text as="h2" typography="t24Leitura">
              {subTitle}
            </UI.Text>
          )}
        </UI.Wrap>
      </Header>
      {media && (
        <UI.Wrap fluid={{ sm: true, lg: false }}>
          <Media>
            <UI.Media r={{ media }} />
          </Media>
          {mediaCaptionIsVisible && (media as any)?.imageCaption && (
            <MediaCaption>
              <pre>{(media as any)?.imageCaption}</pre>
            </MediaCaption>
          )}
        </UI.Wrap>
      )}
    </Container>
  );
};

export default HeroBlock;
