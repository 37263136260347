import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";
import Layout from "layout/index";
import SiteHead from "components/SiteHead";
import PageBlocks from "components/PageBlocks";
import { SupportedLocales } from "context/Intl";

type ILandingPage = PageProps<Gatsby.LandingPageQuery, { langcode: SupportedLocales }>;

const LandingPage: React.FC<ILandingPage> = ({ location, data, pageContext }) => {
  const { langcode } = pageContext;
  const { title, r, meta } = data.nodeLanding || {};
  const pageBlocks = r?.blocks || [];

  const seoTitle = meta?.find((m) => m?.attributes?.name === "title")?.attributes?.content || title;
  // TODO: is this really named that way?
  const seoImage = meta?.find((m) => m?.attributes?.name === "og:image")?.attributes?.content;
  const seoDescription = meta?.find((m) => m?.attributes?.name === "description")?.attributes?.content;
  const seoCanonical = meta?.find((m) => m?.attributes?.rel === "canonical")?.attributes?.href;

  // trigger a window resize event for ie11 to fix banner display issue
  useEffect(() => {
    if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > 0) {
      const evt = document.createEvent("UIEvents");
      // @ts-ignore
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    } else {
      window.dispatchEvent(new Event("resize"));
    }
  }, []);

  return (
    <Layout location={location} langCode={langcode}>
      <SiteHead
        title={seoTitle}
        thumbnail={seoImage}
        description={seoDescription}
        canonical={seoCanonical}
        pathname={location.pathname}
        language={langcode}
      />
      <PageBlocks blocks={pageBlocks as any} />
    </Layout>
  );
};

export const LandingPageQuery = graphql`
  query LandingPage($slug: Int) {
    nodeLanding(drupal_internal__nid: { eq: $slug }) {
      nid: drupal_internal__nid
      title
      meta: metatag_normalized {
        attributes {
          name
          content
          rel
          href
        }
      }
      r: relationships {
        blocks: field_content {
          ...HeroBlock
          ...TextBlock
          ...CarouselBlock
          ...MediaBlock
          ...CompoundCardBlock
          ...ColumnsBlock
          ...HeroComponentBlock
          ...BreakerBlock
          ...CardCalloutBlock
          ...MyChartBlock
          ...FAQBlock
          ...GlossaryBlock
          ...MobileCTABlock
          ...PolicyBlock
          ...CalloutMediaSliderBlock
        }
      }
    }
  }
`;

export default LandingPage;
