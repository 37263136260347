import React from "react";
import styled from "styled-components";
import * as UI from "components/ui";
import { up } from "styled-breakpoints";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};
  justify-content: space-between;
  flex-direction: column;

  ${up("lg")} {
    flex-direction: row;
    padding-top: ${({ theme }) => theme.spacing(13)};
    padding-bottom: ${({ theme }) => theme.spacing(13)};
  }
`;

const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${up("lg")} {
    width: 35%;
    margin-bottom: 0;
  }
`;

const Body = styled.div`
  ${up("lg")} {
    width: 50%;
  }
`;

const BreakerBlock: React.FC<Gatsby.BreakerBlockFragment> = ({ r }) => {
  const card = r?.field_card && r?.field_card[0];

  if (!card) {
    return null;
  }

  return (
    <UI.Wrap>
      <Container>
        <Title>
          <UI.Text as="h1" typography={"h2Leitura"} color={"anotherBlue"}>
            {card.field_title}
          </UI.Text>
        </Title>
        {card.field_body?.value && (
          <Body>
            <UI.Text as="div" typography={"t16Fakt"} dangerouslySetInnerHTML={{ __html: card.field_body?.value }} />
          </Body>
        )}
      </Container>
    </UI.Wrap>
  );
};

export default BreakerBlock;
