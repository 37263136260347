import React from "react";
import { uid } from "react-uid";

import HeroBlock from "components/block/HeroBlock";
import TextBlock from "components/block/TextBlock";
import CarouselBlock from "components/block/CarouselBlock";
import MediaBlock from "components/block/MediaBlock";
import ColumnsBlock from "components/block/ColumnsBlock";
import CompoundCardBlock from "components/block/CompoundCardBlock";
import HeroComponentBlock from "components/block/HeroComponentBlock";
import BreakerBlock from "components/block/BreakerBlock";
import CardCalloutBlock from "components/block/CardCalloutBlock";
import MyChartBlock from "components/block/MyChartBlock";
import FAQBlock from "components/block/FAQBlock";
import GlossaryBlock from "components/block/GlossaryBlock";
import MobileCTABlock from "components/block/MobileCTABlock";
import PolicyBlock from "components/block/PolicyBlock";
import CalloutMediaSliderBlock from "components/block/CalloutMediaSliderBlock";

enum BlockName {
  Hero = "paragraph__hero_media",
  Text = "paragraph__text",
  Carousel = "paragraph__gallery_carousel",
  Media = "paragraph__media",
  Compound = "paragraph__compound_card",
  Columns = "paragraph__columns",
  HeroComponent = "paragraph__hero_component",
  Breaker = "paragraph__breaker",
  CardCallout = "paragraph__card_callout",
  MyChart = "paragraph__mychart_component",
  FAQ = "paragraph__faq",
  Glossary = "paragraph__glossary",
  MobileCTA = "paragraph__mobile_cta",
  Policy = "paragraph__policies",
  CalloutMediaSlider = "paragraph__callout_media_slider",
}

interface IBlock {
  __typename: BlockName;
  [k: string]: unknown;
}

interface IProps {
  blocks: IBlock[];
}

type IBlockComponent = {
  key: string;
  block: any;
  Component: React.FC<any>;
};

const BlockMap = {
  [BlockName.Hero]: HeroBlock,
  [BlockName.Text]: TextBlock,
  [BlockName.Carousel]: CarouselBlock,
  [BlockName.Media]: MediaBlock,
  [BlockName.Columns]: ColumnsBlock,
  [BlockName.Compound]: CompoundCardBlock,
  [BlockName.HeroComponent]: HeroComponentBlock,
  [BlockName.Breaker]: BreakerBlock,
  [BlockName.CardCallout]: CardCalloutBlock,
  [BlockName.MyChart]: MyChartBlock,
  [BlockName.FAQ]: FAQBlock,
  [BlockName.Glossary]: GlossaryBlock,
  [BlockName.MobileCTA]: MobileCTABlock,
  [BlockName.Policy]: PolicyBlock,
  [BlockName.CalloutMediaSlider]: CalloutMediaSliderBlock,
} as const;

const MissingPageBlock: React.FC<IBlock> = ({ __typename }) => (
  <section>
    <h1>Missing block "{__typename}".</h1>
  </section>
);

const PageBlock: React.FC<IBlockComponent> = ({ Component, block, key }) => (
  <section key={key}>
    <Component {...block} />
  </section>
);

const PageBlocks: React.FC<IProps> = ({ blocks }) => {
  const mapBlockComponents = (block: IBlock): IBlockComponent => {
    const key = uid(block.nid + block.__typename);
    const Component = BlockMap[block.__typename] || MissingPageBlock;
    return { Component, block, key };
  };

  return <>{blocks.map(mapBlockComponents).map(PageBlock)}</>;
};

export default PageBlocks;
