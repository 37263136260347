import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { uid } from "react-uid";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";
import useMediaQuery from "hooks/useMediaQuery";
import useWindowSize from "hooks/useWindowSize";

const Container = styled.div<{ hasHeadlines: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  width: 100%;

  ${up("md")} {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }

  ${up("lg")} {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }

  ${up("xl")} {
    padding-right: 5vw;
    padding-left: ${({ hasHeadlines }) => (hasHeadlines ? "35vw" : 0)};
    max-width: ${({ hasHeadlines, theme }) => (hasHeadlines ? "auto" : theme.spacing(87))};
    margin-left: auto;
    margin-right: auto;

    h1,
    h2,
    h3,
    h4 {
      position: ${({ hasHeadlines }) => (hasHeadlines ? "absolute" : "static")};
      left: 0;
      top: 0;
      width: 35vw;
      padding-right: ${({ theme }) => theme.spacing(9)};
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

const TextBlock: React.FC<Gatsby.TextBlockFragment> = ({ body, nid }) => {
  const isXl = useMediaQuery("xl");
  const viewportSize = useWindowSize();
  const $container = useRef<HTMLDivElement>(null);
  const hasHeadlines = !!body?.value?.includes("<h") || !!body?.value?.includes("<H");

  useLayoutEffect(() => {
    if (!hasHeadlines || !$container.current || typeof window === "undefined") return;

    Array.from($container.current.querySelectorAll("h1, h2, h3, h4, h5, h6")).forEach((node) => {
      if (!node.parentElement) return;

      if (isXl) {
        node.parentElement.style.minHeight = `${node.clientHeight}px`;
      } else {
        node.parentElement.style.minHeight = `auto`;
      }
    });
  }, [hasHeadlines, viewportSize, isXl]);

  if (!body?.value) return null;

  return (
    <UI.Wrap>
      <Container hasHeadlines={hasHeadlines} ref={$container}>
        <UI.RichText key={uid("rt_" + nid)} content={body.value} />
      </Container>
    </UI.Wrap>
  );
};

export default TextBlock;
