import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Card = styled.article`
  padding-top: ${({ theme }) => theme.spacing(10)};
  padding-bottom: ${({ theme }) => theme.spacing(10)};
  padding-left: ${({ theme }) => theme.spacing(10)};
  padding-right: ${({ theme }) => theme.spacing(10)};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette["anotherLightBlue"]};
  height: 100%;

  ${up("xl")} {
    padding-left: ${({ theme }) => theme.spacing(20)};
    padding-right: ${({ theme }) => theme.spacing(20)};
  }
`;

const Title = styled(UI.Text)`
  display: block;
  text-align: center;
  max-width: 23ch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Image = styled.div`
  width: 100%;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const CardLink = styled.div`
  text-align: center;
`;

const CompoundCardBlock: React.FC<Gatsby.CompoundCardBlockFragment> = ({ r, nid }) => {
  const ctaCards =
    r?.field_card
      ?.filter((c: any) => c.__typename === "paragraph__cta")
      .map((c: any) => {
        const fieldCard = c?.r?.field_card.length > 0 ? c?.r?.field_card[0] : false;
        return {
          title: fieldCard?.field_title,
          image: {
            ...fieldCard?.r?.field_media?.r?.image?.localFile,
            altName: fieldCard?.r?.field_media?.imageDetails?.alt || fieldCard?.field_title || "",
          },
          links:
            (fieldCard &&
              fieldCard.r?.field_cta_details?.map((details: any) => ({
                title: (details?.field_link || [])[0]?.title || "",
                link: (details?.field_link || [])[0]?.uri || "",
              }))) ||
            [],
        };
      }) || [];

  let card: any = r?.field_card?.find((c: any) => c.__typename === "paragraph__card");

  card = {
    title: card?.field_title,
    image: {
      ...card?.r?.field_media?.r?.image?.localFile,
      altName: card?.r?.field_media?.imageDetails?.alt || card?.field_title || "",
    },
    link: {
      title: (card?.field_link || [])[0]?.title,
      link: (card?.field_link || [])[0]?.uri,
    },
  };

  return (
    <Container>
      <UI.Wrap>
        <UI.Grid.Row colGap={{ sm: 1, lg: 2, xl: 2 }} rowGap={{ sm: 4, lg: 0 }}>
          {ctaCards.map((card, i) => {
            return (
              <UI.Grid.Col size={{ sm: 1, lg: 1 / 2 }} key={nid + "" + i}>
                <UI.CardCTA title={card.title} image={card.image} links={card.links} />
              </UI.Grid.Col>
            );
          })}
          {card && (
            <UI.Grid.Col size={{ sm: 1, lg: 1 / 2 }} key={nid} align="stretch">
              <Card>
                {card.image && (
                  <Image>
                    <UI.Image {...card.image} alt={card.image?.altName} />
                  </Image>
                )}
                <Title as="h1" typography={"h4Leitura"}>
                  {card.title}
                </Title>
                <CardLink>
                  <UI.Link to={card.link.link || ""} showUnderline={true}>
                    <UI.Text typography={"t14Fakt"}>{card.link.title}</UI.Text>
                  </UI.Link>
                </CardLink>
              </Card>
            </UI.Grid.Col>
          )}
        </UI.Grid.Row>
      </UI.Wrap>
    </Container>
  );
};

export default CompoundCardBlock;
