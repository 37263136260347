import debounce from "lodash.debounce";
import { useState, useEffect, useCallback } from "react";

const useWindowSize = () => {
  const isBrowser = typeof window !== "undefined";

  const getSize = useCallback(
    () => ({
      width: isBrowser ? window.innerWidth : null,
      height: isBrowser ? window.innerHeight : null,
    }),
    [isBrowser]
  );

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isBrowser) return;

    const handleResize = debounce(() => setWindowSize(getSize()), 50);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getSize, isBrowser]);

  return windowSize;
};

export default useWindowSize;
