import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";

const Container = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${up("md")} {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }

  ${up("lg")} {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

const MediaBlock: React.FC<Gatsby.MediaBlockFragment> = (props) => {
  return (
    <Container>
      <UI.Wrap>
        <UI.Media {...props} />
      </UI.Wrap>
    </Container>
  );
};

export default MediaBlock;
