import React, { useEffect } from "react";
import styled from "styled-components";
import * as UI from "components/ui";
import { Typography } from "styles/mixins";
import { up } from "styled-breakpoints";

const Container = styled.div``;

const Answer = styled.div`
  ${Typography.t16Fakt}

  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  a {
    text-decoration: underline;
  }

  ${up("lg")} {
    width: 100%;
  }
  ${up("xl")} {
    width: 50%;
  }
`;

const CTA = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette["greyNightmare"]};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(8)};
  padding-right: ${({ theme }) => theme.spacing(8)};
  justify-content: center;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spacing(2)};

  ${up("lg")} {
    display: inline-flex;
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

const FAQBlock: React.FC<Gatsby.FAQBlockFragment> = ({ r, nid }) => {
  const faqItems = r?.field_faq_items || [];
  const faqItem = faqItems.length > 0 ? faqItems[0] : null;
  const question = faqItem?.field_question;
  const answer = faqItem?.field_answer?.value;
  const link = faqItem?.field_link && faqItem?.field_link.length > 0 ? faqItem?.field_link[0] : null;

  if (!question || !answer) {
    return null;
  }

  return (
    <UI.Wrap>
      <Container>
        <UI.Expandable title={question} managed={String(nid)} id={String(nid)}>
          <Answer dangerouslySetInnerHTML={{ __html: answer }} />
          {link?.url && (
            <CTA>
              <a href={link.url}>
                <UI.Text typography={"t16Fakt"} color={"white"}>
                  {link.title}
                </UI.Text>
              </a>
            </CTA>
          )}
        </UI.Expandable>
      </Container>
    </UI.Wrap>
  );
};

export default FAQBlock;
