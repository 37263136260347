import React, { useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { uid } from "react-uid";
import { up } from "styled-breakpoints";
import GalleryCarousel from "components/GalleryCarousel";
import * as UI from "components/ui";
import ChevronRightIcon from "assets/chevron-right.svg";
import ChevronLeftIcon from "assets/chevron-left.svg";

const Container = styled.div`
  // position: relative;
  display: flex;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(7)};

  ${up("lg")} {
    margin-bottom: ${({ theme }) => theme.spacing(15)};
  }
`;

const ControlWrapper = styled.div<{ layout: string }>`
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  position: ${({ layout }) => layout === "gallery" && "absolute"};
  width: ${({ layout }) => (layout === "gallery" ? "90vw" : "100%")};
  margin-left: ${({ layout }) => layout === "gallery" && "5vw"};
  margin-right: ${({ layout }) => layout === "gallery" && "5vw"};
  padding-top: ${({ layout }) => (layout === "gallery" ? "60px" : "40px")};
  padding-bottom: ${({ layout }) => (layout === "gallery" ? "60px" : "40px")};
  top: 50vw;

  ${up("lg")} {
    border-top: ${({ layout }) => layout === "gallery" && "2px solid rgba(255,255,255,0.2)"};
    justify-content: flex-end;
    bottom: 0;
    top: auto;
  }
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  display: flex;
  position: relative;
  width: auto;
  transition: left 0.3s ease-in;
`;

const CardWrapper = styled.div<{ layout: string }>`
  margin-right: ${({ layout }) => (layout === "card" ? "16px" : 0)};
  max-width: ${({ layout }) => layout === "card" && "400px"};
`;

const CarouselBlock: React.FC<Gatsby.CarouselBlockFragment> = ({ r, layout = "gallery" }) => {
  const cards =
    r?.slides?.map((slide) => ({
      title: slide?.title || "",
      image: {
        ...((slide?.r?.media?.r as any)?.image?.localFile || undefined),
        altName: (slide?.r?.media as any)?.imageDetails?.alt || slide?.title || "",
      },
      layout: slide?.layout,
      body: slide?.body?.value || "",
      backgroundColor: slide?.backgroundColor,
    })) || [];

  const [leftPos, setLeftPos] = useState(0);
  const [delta, setDelta] = useState(0);
  const [gap, setGap] = useState(16);
  const [visibleWidth, setVisibleWidth] = useState(0);
  const wrapRef = useRef<HTMLDivElement | null>(null);

  const scrollWidth = cards.length * delta + cards.length * gap;

  useLayoutEffect(() => {
    if (!!wrapRef?.current) {
      setVisibleWidth(wrapRef.current.clientWidth);
      setDelta(layout === "card" ? 400 + 16 : window.innerWidth);
      setGap(layout === "card" ? 16 : 0);
    }
  }, []);

  const goLeft = () => {
    let pos = leftPos;
    if (leftPos < 0) {
      pos += delta + gap;
    }
    if (pos > 0) {
      pos = 0;
    }
    setLeftPos(pos);
  };

  const goRight = () => {
    let pos = leftPos - (delta + gap);
    const scrollRest = scrollWidth - visibleWidth;
    if (-scrollRest > pos) {
      pos = -scrollRest;
    }
    setLeftPos(pos);
  };

  if (layout === "gallery") {
    return (
      <GalleryCarousel
        slides={r?.slides?.map((slide) => ({
          title: slide?.title || "",
          image: {
            ...(slide?.r?.media?.r as any)?.image?.localFile,
            altName: (slide?.r?.media as any)?.imageDetails?.alt || slide?.title || "",
          },
          body: slide?.body?.value || "",
        }))}
      />
    );
  }

  return (
    <Container>
      <UI.Wrap ref={wrapRef} fluid={layout === "gallery"}>
        <ControlWrapper layout={layout}>
          <UI.Button onClick={goLeft} variant="grey" round>
            <UI.Icon>
              <ChevronLeftIcon />
            </UI.Icon>
          </UI.Button>
          &nbsp;&nbsp;
          <UI.Button onClick={goRight} variant="grey" round>
            <UI.Icon>
              <ChevronRightIcon />
            </UI.Icon>
          </UI.Button>
        </ControlWrapper>
        <ScrollWrapper style={{ width: scrollWidth, left: leftPos }}>
          {cards.map((card, i) => {
            return (
              <CardWrapper key={uid(i)} layout={layout}>
                {layout === "card" && <UI.Card {...card} wrapTitle={true} fillParent={true} />}
                {layout === "gallery" && <UI.FullCard {...card} />}
              </CardWrapper>
            );
          })}
        </ScrollWrapper>
      </UI.Wrap>
    </Container>
  );
};

export default CarouselBlock;
