import React from "react";
import { uid } from "react-uid";
import * as UI from "components/ui";
import styled from "styled-components";

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const ColumnsBlock: React.FC<Gatsby.ColumnsBlockFragment> = ({ r }) => {
  const cardCount = r?.columns?.length;

  const cards =
    r?.columns?.map((slide, i) => ({
      title: slide?.title || "",
      image: {
        ...(slide?.r?.media?.r as any)?.image?.localFile,
        altName: (slide?.r?.media as any)?.imageDetails?.alt || slide?.title || "",
      },
      layout: slide?.layout,
      body: slide?.body?.value || "",
      backgroundColor: slide?.backgroundColor,
      size: cardCount === 2 && i === 0 ? 2 / 3 : 1 / 3,
    })) || [];

  return (
    <Container>
      <UI.Wrap fluid={{ sm: true, lg: false }}>
        <UI.Grid.Row colGap={{ sm: 0, lg: 2 }}>
          {cards.map(({ size, ...card }, i) => {
            return (
              <UI.Grid.Col key={uid(i)} size={{ sm: 1, lg: size }} align="stretch">
                <UI.Card {...card} fillParent={true} noBorder={true} />
              </UI.Grid.Col>
            );
          })}
        </UI.Grid.Row>
      </UI.Wrap>
    </Container>
  );
};

export default ColumnsBlock;
